<template>
	<el-dialog
		:title="titleDialog"
		:visible="showDialog"
		@close="close"
		@open="create"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		width="60%"
		append-to-body
	>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.name }">
						<label>Nombre</label>
						<el-input v-model="form.name"></el-input>
						<small
							class="error--text"
							v-if="errors.name"
							v-text="errors.name[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="6" md="12" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.email }">
						<label>Correo Electrónico</label>
						<el-input v-model="form.email" type="email"></el-input>
						<small
							class="error--text"
							v-if="errors.email"
							v-text="errors.email[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="6" md="12" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.password }">
						<label>Contraseña</label>
						<el-input v-model="form.password" type="password" show-password></el-input>
						<small
							class="error--text"
							v-if="errors.password"
							v-text="errors.password[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="6" md="12" sm="12">
					<div
						class="form-group"
						:class="{ 'error--text': errors.password_confirmation }"
					>
						<label>Confirmar Contraseña</label>
						<el-input
							v-model="form.password_confirmation"
							type="password_confirmation"
							show-password
						></el-input>
						<small
							class="error--text"
							v-if="errors.password_confirmation"
							v-text="errors.password_confirmation[0]"
						></small>
					</div>
				</v-col>
			</v-row>

			<el-divider>Establecimientos</el-divider>

			<v-row>
				<v-col
					cols="12"
					xl="6"
					lg="12"
					md="12"
					sm="12"
					v-for="(establishment, index) in form.establishments"
					:key="index"
				>
					<el-checkbox v-model="establishment.checked">{{
						establishment.name
					}}</el-checkbox>
				</v-col>
			</v-row>

			<el-divider>Roles</el-divider>

			<v-row>
				<v-col
					cols="12"
					xl="6"
					lg="12"
					md="12"
					sm="12"
					v-for="(role, index) in form.roles"
					:key="index"
				>
					<el-checkbox v-model="role.checked">{{ role.title }}</el-checkbox>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loadingSubmit">
						<template v-if="loadingSubmit">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import { confirm } from 'Mixins/confirm';
import filter from 'lodash/filter';

export default {
	props: ['showDialog', 'recordId', 'typeUser'],
	mixins: [deletable, confirm],
	data() {
		return {
			loadingSubmit: false,
			titleDialog: null,
			resource: 'users',
			errors: {},
			form: {},
			routes: [],
			checkAll: false,
			isIndeterminate: true,
		};
	},
	created() {
		//
	},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				name: null,
				email: null,
				password: null,
				password_confirmation: null,
				locked: false,
				roles: [],
				routes: [],
				establishments: [],
			};
		},
		async create() {
			this.titleDialog = this.recordId ? 'Editar usuario' : 'Nuevo usuario';
			await this.initForm();
			if (!this.recordId) await this.getTables();
			if (this.recordId) {
				await this.$http
					.get(`/${this.resource}/record/${this.recordId}`)
					.then((response) => {
						this.form = response.data.data;
					});
			}
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.form.roles = response.data.roles;
				this.form.establishments = response.data.establishments;
				this.form.routes = response.data.routes;
			});
		},
		handleCheckAllChange() {
			if (this.isIndeterminate) {
				this.form.modules.forEach((module) => {
					module.checked = true;
				});
				this.isIndeterminate = false;
			} else {
				this.form.modules.forEach((module) => {
					module.checked = false;
				});
				this.isIndeterminate = true;
			}
		},
		submit() {
			let establishments = filter(this.form.establishments, { checked: true });
			if (establishments.length == 0) {
				return this.$message.error('Asigna por lo menos un establecimiento al usuario.');
			}

			let roles = filter(this.form.roles, { checked: true });
			if (roles.length == 0) {
				return this.$message.error('Asigna uno o más roles al usuario.');
			}

			this.confirm().then(() => {
				this.loadingSubmit = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.form.password = null;
							this.form.password_confirmation = null;
							this.$message.success(response.data.message);
							this.$eventHub.$emit('reloadData', this.resource);
							this.close();
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loadingSubmit = false;
					});
			});
		},
		validateEstablishments() {
			let establishments = filter(this.form.establishments, { checked: true });
			if (establishments.length == 0) {
				return this.$message.error('Asigna por lo menos un establecimiento al usuario.');
			}
		},
		validateRoles() {
			let roles = filter(this.form.roles, { checked: true });
			if (roles.length == 0) {
				return this.$message.error('Asigna uno o más roles al usuario.');
			}
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
