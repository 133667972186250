<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Usuarios</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" @click.prevent="clickCreate()">Nuevo</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table-without-filter :resource="resource">
				<tr slot="heading">
					<th>N°</th>
					<th>Email</th>
					<th>Nombre</th>
					<th>Establecimiento(s)</th>
					<th>Rol(es)</th>
					<th class="text-right">Acciones</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td>{{ index }}</td>
					<td>{{ row.email }}</td>
					<td>{{ row.name }}</td>
					<td>
						<v-chip
							class="ma-2"
							color="red"
							text-color="white"
							x-small
							v-for="(establishment, indEst) in row.establishments"
							:key="indEst"
						>
							{{ establishment }}
						</v-chip>
					</td>
					<td>
						<v-chip
							class="ma-2"
							color="red"
							text-color="white"
							x-small
							v-for="(role, i) in row.roles"
							:key="i"
						>
							{{ role }}
						</v-chip>
					</td>
					<td class="text-right">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="secondary"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickCreate(row.id)"
									v-on="on"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Modificar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="accent"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickDelete(row.id)"
									v-on="on"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span>Eliminar</span>
						</v-tooltip>
					</td>
				</tr>
			</emb-data-table-without-filter>
		</v-card>

		<user-form :showDialog.sync="showDialog" :recordId="recordId"></user-form>
	</div>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import UserForm from './Form.vue';

export default {
	data() {
		return {
			showDialog: false,
			resource: 'users',
			recordId: null,
			records: [],
		};
	},
	components: {
		UserForm,
	},
	mixins: [deletable],
	methods: {
		clickCreate(recordId = null) {
			this.recordId = recordId;
			this.showDialog = true;
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
